import axios from 'axios';
import { EXCEL_BASE_PATH } from './api-base-paths';


export const importExcel = (data, controller) => {
  return axios.post(`${EXCEL_BASE_PATH}/importExcel`, data, {
    signal: controller?.signal
  });
};




export const storeImportedFile = (data) => {
  return axios.put(`${EXCEL_BASE_PATH}/storeImportedFile`,data,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};


export const deleteSheetFile = (id) => {
  return axios.delete(`${EXCEL_BASE_PATH}/deleteSheet/${id}`);
};


export const getFilesDetails = () => {
  try {
    return axios.get(`${EXCEL_BASE_PATH}/getArchiveData`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getImportedData = (data) => {
  try {
    return axios.post(`${EXCEL_BASE_PATH}/getImportedData`,data);
  } catch (error) {
    console.error("Error fetching imported data:", error);
    throw error;
  }
};


export const getImportedKeys = () => {
  try {
    return axios.get(`${EXCEL_BASE_PATH}/getImportedKeys`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const addRowData = (data, controller) => {
  return axios.post(`${EXCEL_BASE_PATH}/addRow`, data, {
    signal: controller?.signal
  });
};

export const changeStatus = (data, controller) => {
  return axios.post(`${EXCEL_BASE_PATH}/changeStatus`, data, {
    signal: controller?.signal
  });
};

export const getMasterDataById = (id) => {
  return axios.post(`${EXCEL_BASE_PATH}/getMasterDataById`, id);
};

export const updateMasterData = (data, controller) => {
  return axios.post(`${EXCEL_BASE_PATH}/updateMasterData`, data, {
    signal: controller?.signal
  });
};

export const getSheetData = (controller) => {
  try {
    return axios.get(`${EXCEL_BASE_PATH}/getSheetData`, {
      signal: controller?.signal
    });
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getWorkOrderById = (id) => {
  return axios.post(`${EXCEL_BASE_PATH}/getWorkOrderById`, id);
};

export const addNewTask = (data, controller) => {
  return axios.post(`${EXCEL_BASE_PATH}/addNewTask`, data, {
    signal: controller?.signal
  });
};

export const getSearchedValue = (data, controller) => {
  return axios.post(`${EXCEL_BASE_PATH}/getSearchValue`, data, {
    signal: controller?.signal
  });
};

export const getAllWRNumbers = (controller) => {
  try {
    return axios.get(`${EXCEL_BASE_PATH}/getWRNumbers`, {
      signal: controller?.signal
    });
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};


export const getAllUniqueWRNumbers = () => {
  try {
    return axios.get(`${EXCEL_BASE_PATH}/getAllUniqueWRNumbers`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getScheduledWrNo = () => {
  try {
    return axios.get(`${EXCEL_BASE_PATH}/getScheduledWrNo`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getMasterDataForMap = () => {
  try {
    return axios.get(`${EXCEL_BASE_PATH}/getMasterDataForMap`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getCuIdData = (value) => {
  try {
    return axios.put(`${EXCEL_BASE_PATH}/getCuIdData`,value);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};


export const getCuIdDataForScheduling = (value) => {
  try {
    return axios.put(`${EXCEL_BASE_PATH}/getCuIdDataForScheduling`,value);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};


export const updateCrewId = (value) => {
  try {
    return axios.put(`${EXCEL_BASE_PATH}/updateCrewId`,value);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};


export const updateScheduledDate = (value) => {
  try {
    return axios.put(`${EXCEL_BASE_PATH}/updateScheduledDate`,value);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};


export const deleteCrewValue = (id) => {
  try {
    return axios.put(`${EXCEL_BASE_PATH}/deleteCrewValue/${id}`,);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getKey = (controller) => {
  try {
    return axios.get(`${EXCEL_BASE_PATH}/getKey`, {
      signal: controller?.signal
    });
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const addGeneralwrinfo = (data, controller) => {
  console.log(data);
  return axios.post(`${EXCEL_BASE_PATH}/addGeneralwrinfo`, data, {
    signal: controller?.signal
  });
};

export const addReportedCutInfo = (data, controller) => {
  console.log(data);
  return axios.post(`${EXCEL_BASE_PATH}/addReportedCutInfo`, data, {
    signal: controller?.signal
  });
};

export const addCompletedCuid = (data, controller) => {
  console.log(data);
  return axios.post(`${EXCEL_BASE_PATH}/addCompletedCuid`, data, {
    signal: controller?.signal
  });
};

export const updateGeneralwrinfo = (id, data, controller) => {
  return axios.put(`${EXCEL_BASE_PATH}/updateGeneralwrinfo/${id}`, data, {
    signal: controller?.signal
  });
};

export const getGeneralwrinfoById = (id) => {
  return axios.put(`${EXCEL_BASE_PATH}/getGeneralwrinfoById/${id}`);
};



export const updateReportedCutInfo = (id, data, controller) => {
  return axios.put(`${EXCEL_BASE_PATH}/updateReportedCutInfo/${id}`, data, {
    signal: controller?.signal
  });
};

export const getReportedCutinfoById = (id) => {
  return axios.put(`${EXCEL_BASE_PATH}/getReportedCutinfoById/${id}`);
};

export const addFieldReport = (id, data, controller) => {
  return axios.put(`${EXCEL_BASE_PATH}/addFieldReport/${id}`, data);
};

export const updateFieldReport = (id, data) => {
  return axios.put(`${EXCEL_BASE_PATH}/updateFieldReport/${id}`, data);
};

export const getFieldReportById = (id) => {
  return axios.put(`${EXCEL_BASE_PATH}/getFieldReportById/${id}`);
};

export const getFieldReportByWrNo = (id) => {
  return axios.put(`${EXCEL_BASE_PATH}/getFieldReportByWrNo/${id}`);
};

export const deleteFieldReport = (id) => {
  return axios.delete(`${EXCEL_BASE_PATH}/deleteFieldReport/${id}`);
};

export const getCompletedCuidByWrNo = (id) => {
  return axios.put(`${EXCEL_BASE_PATH}/getCompletedCuidByWrNo/${id}`);
};

export const getCompletedCuidById = (id) => {
  return axios.put(`${EXCEL_BASE_PATH}/getCompletedCuidById/${id}`);
};


export const getFileMasterDataByCuId = (id) => {
  return axios.put(`${EXCEL_BASE_PATH}/getFileMasterDataByCuId/${id}`);
};


export const deleteFileByWrNoAndCuid = (id) => {
  return axios.delete(`${EXCEL_BASE_PATH}/deleteFileByWrNoAndCuid/${id}`);
};



export const updateCompletedCuid = (id, data) => {
  return axios.put(`${EXCEL_BASE_PATH}/updateCompletedCuid/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};


export const updateMultipleFilesOfCompletedCUId = (data) => {
  return axios.post(`${EXCEL_BASE_PATH}/updateMultipleFilesOfCompletedCUId`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};


export const getURL = () => {
  try {
    return axios.get(`${EXCEL_BASE_PATH}/getURL`,);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};


export const updatePriorityToggle = (id,toggle) => {
  console.log(toggle)
  return axios.put(`${EXCEL_BASE_PATH}/updatePriorityToggle/${id}/${toggle}`);
};


export const deleteCompletedCuid = (id) => {
  return axios.delete(`${EXCEL_BASE_PATH}/deleteCompletedCuid/${id}`);
};

export const updateExceptionReport = (id, data) => {
  return axios.put(`${EXCEL_BASE_PATH}/updateExceptionReport/${id}`, data);
};



export const updatePermitAndDates = (id,data) => {
  console.log('hello from routeee',data);
  return axios.put(`${EXCEL_BASE_PATH}/updatePermitAndDates/${id}`,data);
};


export const updateComplaintDetails = (id,data) => {
  return axios.put(`${EXCEL_BASE_PATH}/updateComplaintDetails/${id}`,data);
};


export const transferWrNumber = (data) => {
  try{
    return axios.post(`${EXCEL_BASE_PATH}/transferWrNumber`,data);
  }catch(error){
    return error;
  }
};


export const getAllCuidByWrType = (type) => {
  return axios.put(`${EXCEL_BASE_PATH}/getAllCuidByWrType/${type}`);
};



export const addExceptionReport = (id, data, controller) => {

  return axios.put(`${EXCEL_BASE_PATH}/addExceptionReport/${id}`, data, {
    signal: controller?.signal
  });
};

export const getExceptionReportByWrNo = (id) => {
  return axios.put(`${EXCEL_BASE_PATH}/getExceptionReportByWrNo/${id}`);
};

export const getExceptionReportById = (id) => {
  return axios.put(`${EXCEL_BASE_PATH}/getExceptionReportById/${id}`);
};

export const deleteExceptionReport = (id) => {
  return axios.delete(`${EXCEL_BASE_PATH}/deleteExceptionReport/${id}`);
};



export const fetchCompletedWrCancellation = () => {
  try{
    return axios.get(`${EXCEL_BASE_PATH}/getCompletedWrNoForCancellation`);
  }catch(error){
    return error;
  }
};



export const fetchAllCompletedWrCancellation = () => {
  try{
    return axios.get(`${EXCEL_BASE_PATH}/getAllCompletedWrNoForCancellation`);
  }catch(error){
    return error;
  }
};



export const UpdateCancelledWrNumbers = (data) => {
  try{
    return axios.post(`${EXCEL_BASE_PATH}/UpdateCancelledWrNumbers`,data);
  }catch(error){
    return error;
  }
};



export const fetchMassCancelReports = () => {
  try{
    return axios.get(`${EXCEL_BASE_PATH}/getMassCancelRecord`);
  }catch(error){
    return error;
  }
};




export const getExceptionReportByWrNoWithMassCancelledData = (id) => {
  try{
    return axios.put(`${EXCEL_BASE_PATH}/getExceptionReportByWrNoWithMassCancelledData/${id}`);
  }catch(error){
    return error;
  }
};




export const EditMassCancelData = (id) => {
  try{
    return axios.get(`${EXCEL_BASE_PATH}/fetchMassCanelById/${id}`);
  }catch(error){
    return error;
  }
};





export const updateMassCancellationById = (id,data) => {
  try{
    return axios.put(`${EXCEL_BASE_PATH}/updateMassCancellationById/${id}`,data);
  }catch(error){
    return error;
  }
};




export const DeleteMassCancel = (id) => {
  try{
    return axios.get(`${EXCEL_BASE_PATH}/deleteMassCancelById/${id}`);
  }catch(error){
    return error;
  }
};